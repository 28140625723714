export default function useResources (){

    const sections = [
        {
            section: "PROCESADORES",
            image:"/multimedia/cpu-image.png",
            icon: "/multimedia/cpu-icon.png",
            url: "/productos/procesador"
        },
        {
            section: "PLACA MADRE",
            image: "/multimedia/mbo-image.png",
            icon: "/multimedia/mbo-icon.png",
            url: "productos/placa-madre"
        },
        {
            section: "TARJETA GRÁFICA",
            image: "/multimedia/gpu-image.png",
            icon: "/multimedia/gpu-icon.png",
            url: "productos/tarjeta-grafica"
        },
        {
            section: "MEMORIA RAM",
            image: "/multimedia/ram-image.png",
            icon: "/multimedia/ram-icon.png",
            url: "productos/memoria-ram"
        },
        {
            section: "ALMACENAMIENTO",
            image: "/multimedia/ssd-image.png",
            icon: "/multimedia/ssd-icon.png",
            url: "productos/almacenamiento"
        },
        {
            section: "FUENTES DE PODER",
            image: "/multimedia/psu-image.png",
            icon: "/multimedia/psu-icon.png",
            url: "productos/fuente-de-poder"
        },
        {
            section: "CARCASA",
            image: "/multimedia/case-image.png",
            icon: null,
            url: "productos/carcasa"
        },
        {
            section: "ACCESORIOS",
            image: "/multimedia/acc-image.png",
            icon: null,
            url: "productos/accesorios"
        },
    ]

    const videoAdv = [
        {
            video: "/multimedia/about-us.mp4"
        }
    ]

    const imageAdv = [
        {
            image: "/multimedia/adv_1.jpg"
        },
        {
            image: "/multimedia/adv_2.jpg"
        },
        {
            image: "/multimedia/adv_3.jpg"
        },
        {
            image: "/multimedia/adv_4.jpg"
        },
        {
            image: "/multimedia/adv_5.jpg"
        },
        {
            image: "/multimedia/adv_6.jpg"
        },
        {
            image: "/multimedia/adv_7.jpg"
        }
    ]
    
    const brands = [
        {
            image: "/multimedia/amd-logo.png"
        },
        {
            image: "/multimedia/asrock-logo.png"
        },
        {
            image: "/multimedia/cooler-master-logo.png"
        },
        {
            image: "/multimedia/corsair-logo.png"
        },
        {
            image: "/multimedia/gigabyte-logo.png"
        },
        {
            image: "/multimedia/hyperx-logo.png"
        },
        {
            image: "/multimedia/intel-logo.png"
        },
        {
            image: "/multimedia/nvidia-logo.png"
        },
        {
            image: "/multimedia/zotac-logo.png"
        }
    ]

    return {sections,videoAdv,imageAdv,brands}
}