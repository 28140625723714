import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import banner from "./Assets/Image/banner 1.png"
import { Box, Text } from "@chakra-ui/react";

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: (i) => (
        <Box
            position='relative'
            top='-25px'
            width="1rem"
            height="1rem"
            bg="white"
            borderRadius="50%"
            m="0 5px"
            _hover={{ bg: "blue.500" }}
            transition="background-color 0.3s"
        />
      ),
      dotsClass: "slick-dots custom-dots",
  };

  return (
    <Box width="90%" mx="auto" pb='2rem'>
      <Slider {...settings}>
        <Box
            height='35rem'
            backgroundImage={banner}
            backgroundSize='cover'
            borderRadius='1rem'
        >
          <Text color="white">Slide 1</Text>
        </Box>
        <Box
            height='35rem'
            backgroundImage={`/multimedia/advBig.jpg`}
            backgroundSize='cover'
            backgroundPosition='center'
            borderRadius='1rem'
        >
          <Text color="white">Slide 2</Text>
        </Box>
        <Box
            height='35rem'
            backgroundImage='/multimedia/adv_1.jpg'
            backgroundSize='cover'
            backgroundPosition='center'
            borderRadius='1rem'
        >
          <Text color="white">Slide 3</Text>
        </Box>
      </Slider>
    </Box>
  );
}

export default Carousel;